<template>
	<div>
		<div class="house_item" @click="toDetail">
			<div class="select_icons" @click.stop="isSelectClick">
				<img src="@/assets/login/un_select.png" v-if="!collectItem.isCheck">
				<img src="@/assets/login/check.png" v-else>
			</div>
			<div class="house_img">
				<img :src="collectItem.thumb[0]">
			</div>
			<div class="house_title">
				{{collectItem.title}}
			</div>
			<div class="house_price">
				<div class="house_price_left">
					<div v-if="collectItem.price!=0.00"> ${{collectItem.price}}</div>
				</div>
				<div class="house_price_right">
					{{collectItem.create_time}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["collectItem"],
		data() {
			return {}
		},
		methods: {
			toDetail() {
				this.$router.push({
					path: '/detail',
					query: {
						information_id: this.collectItem.id,
					}
				});
			},
			isSelectClick() {
				this.collectItem.isCheck = !this.collectItem.isCheck;
				this.$emit('collectionItemClick', this.collectItem);
			}
		}
	}
</script>

<style scoped="" lang="less">
	.house_item {
		cursor: pointer;
		box-sizing: border-box;
		width: 240px;
		background-color: #FFFFFF;
		position: relative;

		&:nth-of-type(3n) {
			margin-right: 0;
		}

		.house_img {
			width: 240px;
			height: 240px;

			img {
				width: 240px;
				height: 240px;
			}
		}

		.house_title {
			line-height: 26px;
			color: #333333;
			font-size: 14px;
			margin: 16px 0 12px;
			word-break: break-all;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			/* 这里是超出几行省略 */
			overflow: hidden;
			text-align: left;

		}

		.house_price {
			display: flex;
			justify-content: space-between;

			.house_price_left {
				color: #F10200;
				font-weight: bold;
				font-size: 16px;
			}

			.house_price_right {
				color: #999999;
				font-size: 14px;
			}
		}
	}

	.select_icons {
		position: absolute;
		top: 10px;
		left: 10px;

		img {
			width: 30px;
			height: 30px;
		}
	}
</style>
