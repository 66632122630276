<template>
	<div class="person_infor">
		<div class="login_top">
			<div class="loign_top_left">
				<div class="img"> <img src="@/assets/login/flag.png"></div>
				<div class="login_title">{{$t('title.myCollect')}}</div>
			</div>
			<div class="login_top_right" @click="deleteSelect">
				{{$t('deleteSelectList')}}
			</div>
		</div>
		<div class="all_collect" v-if="collectionList.length>0">
			<div class="collect_item" v-for="(item,index) in collectionList" :key="index">
				<collectItem :collectItem="item" @collectionItemClick="collectionItemClick"></collectItem>
			</div>
		</div>
		<div class="empty" v-else>
			<el-empty :image-size=" 200" :description="$t('noData')">
			</el-empty>
		</div>
		<div class="pagination">
			<el-pagination background layout="prev, pager, next,sizes" :total="total"
				@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
				:page-size="pageSize" :background="true" popper-class="select_bottom" :page-sizes="pageSizes">
			</el-pagination>
		</div>


		<el-dialog :visible.sync="deleteVisible" width="30%" center :show-close="false">
			<span slot="title">
				<span style="font-weight: bold;">{{$t('my.tips')}}</span>
			</span>
			<div class="delete_info">{{$t('my.deleteAllContent')}}</div>
			<span slot="footer" class="dialog-footer">
				<div class="button">
					<div class="btn cancel" @click="deleteVisible=false">{{$t('cancel')}}</div>
					<div class="btn confirm" @click="deleteCollect">{{$t('confirm')}}</div>
				</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import collectItem from './collectItem.vue'
	export default {
		components: {
			collectItem,
		},
		data() {
			return {
				radio: 3,
				value1: '',
				deleteVisible: false,

				collectionList: [], //收藏列表

				total: 0, //总条数
				currentPage: 1, //当前页数
				pageSize: 6, //每页的条数
				pageSizes: [6, 10, 20, 30, 40, 50, 100],
			}
		},
		mounted() {
			this.getCollection();
		},
		methods: {
			getCollection() {
				let data = {
					pageNo: this.currentPage,
					pageSize: this.pageSize,
				};
				this.$http.collectionList(data).then(res => {
					console.log(res);
					if (res.code == 1) {
						this.collectionList = res.data.data;
						this.total = res.data.total;
						this.collectionList = this.collectionList.map(item => {
							return {
								...item,
								isCheck: false,
							}
						})
					} else {

					}
				})
			},
			currentChangeClick(currentPage) {
				this.currentPage = currentPage;
				this.getCollection();

			},
			sizeChangeClick(pageSize) {
				this.pageSize = pageSize;
				this.getCollection();
			},

			// 子组件传到父组件
			collectionItemClick(e) {
				console.log(e);
				this.collectionList.forEach(item => {
					if (e.id == item.id) {
						item.isCheck = e.isCheck;
					}
				})
			},
			// 控制是否提示
			deleteSelect() {
				let result = this.collectionList.filter(item => item.isCheck);
				console.log(result.length);
				if (result.length == 0) {
					this.$message.info(this.$t('tips.pleaseCheckAtLeastOneItem'));
					return;
				}
				this.deleteVisible = true
			},
			// 删除选中的内容
			deleteCollect() {
				console.log(this.collectionList);
				let deleteCollectList = this.collectionList.filter(item => item.isCheck).map(item => item.id).join(",");
				let data = {
					type: 2,
					information_id: deleteCollectList,
				}
				this.$http.cancel_collection(data).then(res => {
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.getCollection();
						this.deleteVisible = false;
					} else {
						this.$message.error(res.msg);
					}
				})
			}
		}
	}
</script>

<style scoped="" lang="less">
	.person_infor {
		width: 926px;
	}

	.login_top {
		display: flex;
		justify-content: space-between;
		padding: 24px;
		border-bottom: 1px solid #F3F5F6;

		.loign_top_left {
			display: flex;

			.img {
				display: flex;
				align-items: center;

				img {
					width: 18px;
					height: 18px;
				}
			}

			.login_title {
				margin-left: 8px;
				font-weight: bold;
				font-size: 18px;
			}
		}

		.login_top_right {
			color: #999999;
			font-size: 14px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.all_collect {
		display: flex;
		flex-wrap: wrap;
		padding: 24px;

		.collect_item {
			margin-right: 10px;
			margin-bottom: 10px;
			padding: 22px;
			border: 1px solid #F0F0F0;

			&:nth-of-type(3n) {
				margin-right: 0;
			}
		}
	}

	.delete_info {
		color: #666666;
		font-size: 14px;
		width: 224px;
		margin: 0 auto;
		display: flex;
		justify-content: center;
	}

	.button {
		padding-top: 12px;
		display: flex;
		justify-content: center;
		padding-bottom: 24px;


		.btn {
			width: 104px;
			height: 40px;
			line-height: 40px;
			text-align: center;
			opacity: 1;
			border-radius: 8px;
			font-size: 14px;
		}

		.btn:hover {
			cursor: pointer;
		}

		.cancel {
			background: #F6F7F9;
			color: #666666;
			margin-right: 80px;
		}

		.confirm {
			background: #E60012;
			color: #FFFFFF;
		}
	}

	.pagination {
		margin-top: 24px;
		padding-bottom: 24px;
		display: flex;
		justify-content: flex-end;
	}
</style>
